<template>
    <v-app  :class="!$vuetify.breakpoint.xs ? 'app-desktop' : 'app-mobile'">

    <AppToolbar></AppToolbar>

    <v-content class="app-content">
      <transition name="slide-fade" mode="out-in">
      <!-- <transition name="slide" mode="out-in"> -->
        <router-view class="mt-6"></router-view>
      </transition>
    </v-content>

    <AppFooter />
  </v-app>
</template>

<script>
import AppToolbar from "./components/Header.vue";
import AppDrawer from "./components/AppDrawer.vue";
import AppFooter from "./components/Footer.vue";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    tabs: null,
    links: [
      'Profile',
      'Portfolio - Skills',
      'Contact',
    ],
  }),
  components: {
    AppToolbar,
    AppDrawer,
    AppFooter
  },
  methods: {
    toggleAppDrawer () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.app-desktop {
  /* padding-left: 15%; */
  /* padding-right: 15%; */
  background-color: #000000 !important;
}

.app-mobile {
  padding-left: 0;
  padding-right: 0;
  background-color: #000000 !important;
}

main {
  display: block !important;
}

img[alt="www.000webhost.com"]{display:none};

.navv {
  height: 100px !important;
}
#inspire {
  background-color: #070707;
}
.code-container {
  font-family: 'Courier New', monospace;
  font-size: 18px;
  background-color: #070707;
  color: #f8f8f8;
  padding: 6px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.code-container-skills {
  font-family: 'Courier New', monospace;
  font-size: 18px;
  background-color: #070707;
  color: #f8f8f8;
  padding: 6px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.code-container-menus {
  font-family: 'Courier New', monospace;
  font-size: 18px;
  background-color: #767676;
  color: #f8f8f8;
  padding: 6px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.scrollable-tab {
  transition: transform 0.3s ease; /* Adjust duration and easing */
  will-change: transform;
}

.logo-text {
  font-size: 25px;
  display: flex;
  padding-top: 20px;
}


.logo-text-one {
  color: #a5a5a5;
}

.logo-text-two {
  margin-left: -30px;
  color: #59fd08;
}
</style>