<template>
    <v-list class="mb-3">
      <v-list-item-group v-model="item" style="display: inline;">
        <v-list-item disabled class="mt-5">
            <v-list-item-title style="display: grid;">
                <img class="mb-3" :src="require('../assets/logo.png')" height="40"/>
                <h4 class="black--text mb-3">Open bridges for education</h4>
            </v-list-item-title>
        </v-list-item>
      
      <v-divider></v-divider>
  
      <v-list-item
        class="mt-2"
        v-for="item in items"
        :key="item"
        link
        :to="item.to"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      </v-list-item-group>
    </v-list>
</template>

<script>
export default {
data () {
    return {
        items: [
        { title: "Vision", to: "/" },
        { title: "Publications", to: "/publications" },
        { title: "Members", to: "/members" },
        { title: "About", to: "/about" },
        { title: "Contact", to: "/contact" },
        ]
    }
}
}
</script>
