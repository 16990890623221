<template>
    <v-card   class="" style="height: 10%; background-color: transparent;">
      <v-toolbar  class="head elevation-0">

          <div @click="$router.push('/')" class="logo-text">
              <h1 class="logo-text-one">H</h1>
              <h1 class="logo-text-two">H</h1>
          </div>

          <v-spacer></v-spacer>
          <v-icon class="white--text social-icon" @click="goToLinkedIn">mdi-linkedin</v-icon>
  
      </v-toolbar>
    </v-card>
</template>
  
<script>
  export default {
    name: "Header",
    data: () => ({
    }),
    methods: {
    goToLinkedIn() {
      // Add the LinkedIn profile URL or any action you want
      window.open('https://www.linkedin.com/in/ch-hamza-tariq/', '_blank');
    },
  },
  };
</script>
  
<style>
  .head {
    background-color: #000000 !important;
  }
  
  .v-toolbar__content {
    display: flex;
  }

.logo-text {
  font-size: 25px;
  display: flex;
  padding-top: 20px;
}

.logo-text-one {
  color: #a5a5a5;
}

.logo-text-two {
  margin-left: -30px;
  color: #59fd08;
}

.social-icon {
  color: #ffffff;
  cursor: pointer;
  font-size: 40px;
  margin-right: 20px;
}
</style>
  